<script>
import Vertical from "./vertical";

export default {
    components: {
        Vertical,
    },

    mounted() {
        let sp_token = false;
        for (let account of this.$store.state.account.accounts || []) {
            if (account.sp_api_tokens.length > 0) {
                sp_token = true;
                break;
            }
        }
        if (this.$router.currentRoute._rawValue.name !== 'Connections' && !sp_token) {
            this.$notify('error', 'No active connections found')
        }
    },

    methods: {
    },

    data(){return{
        showAlert: false
    }}

};
</script>

<template>
    <div>
        <Vertical>
            
            <slot />
        </Vertical>
    </div>
</template>
