<!-- eslint-disable no-unused-vars -->
<script>

import Multiselect from "@vueform/multiselect";
import { authRequest, getHeader } from '@/helpers/auth.api';
import { productsMethods } from "@/state/helpers"

export default {

    props:{
        page:{type:String, default:null},
        placeholder: {type:String, default:'Filter by asin, sku, name, tag, note etc...'},
        max: {type: Number, default:-1},
        closeOnSelect:{type:Boolean, default:false},
        mode:{type:String, default:'tags'},
    },

    components: { Multiselect },

    computed:{
        marketplace: {
            get(){return window.global_store.state.marketplace.selected_marketplace},
        },
    },

    mounted(){
        let selected_products = window.global_store.state.products.selected_products
        if (selected_products)
        this.$refs.multiselect.select(selected_products)
    },

    methods: {
        ...productsMethods,
        //getHeader: getHeader,
        setInitial(products) { },
        selected(val) {
            if (val == 'clear') {
                this.value = []
            }

            this.setProducts(this.value)
        },

        async fetchProducts(query) {
            //const getHeader = await require('@/helpers/auth.api').getHeader
            return await authRequest.get(`product/sku-list/`, {
                headers: getHeader(),
                params: {
                    marketplace : this.marketplace.marketPlaceID,
                    sp_api_token_id : this.marketplace.sp_api_token_id,
                    query:query,
                    skip: 0,
                    limit: this.take,
                },
                paramsSerializer: {
                    indexes: null,
                }
            },
            )
                .then(res => {
                    return Array.prototype.map.call(res.data, (el) => {
                        return {
                            value: el.seller_sku,
                            label: el.seller_sku,
                            product: el
                        }
                    })
                    // this.filtered = res.data.result
                    //return res.data.result
                })
                .catch(err => { return null })
        },

        open: (select$) => {
            if (select$.noOptions) {
                select$.resolveOptions()
            }
        },
    },

    watch: {
        marketplace(newVal, oldVal) {
            this.$refs.multiselect.refreshOptions()
            this.value = []
        },
    },

    data() {
        return {
            take: 99,
            filtered: [],
            value:null
        }
    },
}
</script>

<template>
    <Multiselect ref="multiselect"
    :classes="{ optionSelected: 'text-white',
                optionSelectedPointed: 'text-white bg-gray-500 opacity-90',
                option:'p-0', 
 }" 
    class="form-control"
    :allowAbsent="true" 
    :object="true" 
    @select="selected" 
    @deselect="selected" 
    @clear="selected('clear')" 
    v-model="value"
    :hideSelected="false" 
  :mode="mode"
  :placeholder="placeholder"
  :close-on-select="closeOnSelect"
  :filter-results="false"
  :min-chars="0"
  :resolve-on-load="false"
  :infinite="true"
  :limit="5"
  :max="max"
  :clear-on-search="true"
  :delay="500"
  :searchable="true"
  :options="async (query) => {
    return await fetchProducts(query)
  }"
  @open="(select$) => {
    if (select$.noOptions) {
      select$.resolveOptions()
    }
  }">

        <template v-if="mode=='single'" v-slot:singlelabel="{ value }">
            <div class="multiselect-single-label">
                <img class="" :src="value.product.imageURL" width="20" height="20"> 
                <span class="mx-2 float-end long-text">{{ value.product.brandName }}</span>
            </div>
        </template>

        <template v-slot:multiplelabel="{ values }">
            <span class="multiselect-multiple-label float-end">{{ values.length }} product selected </span>
        </template>

        <template v-slot:option="{ option, isSelected } ">
            <div class="hstack form-control rounded-0">
                <img :src="option.product.imageURL" width="50" height="50" />
                <div class="vstack">
                    <span class="mx-3 fw-bold">{{ option.label }}/{{ option.product.asin }}</span>
                    <span class="mx-3">{{ option.product.brandName != null ?  option.product.brandName.trim() : option.product.brandName}}</span>
                    <span class="mx-3">{{ option.product.item_name != null ?  option.product.item_name.trim() : option.product.item_name}}</span>
                </div>
                <span v-if="isSelected(option)" class="float-end badge badge-soft-success fst-italic">
                    selected</span>
            </div>
        </template>

    </Multiselect>
</template>

<style>
.multiselect-tag.is-user {
    margin: 3px 3px 8px;
    margin-bottom: 20px;
}

.multiselect-tag.is-user img {
    width: 70px;
    height: 70px;
    margin-right: 8px;
}
</style>