<script>
import animationData from "@/components/widgets/msoeawqm.json";
import Lottie from "@/components/widgets/lottie.vue";
export default {
    components:{ Lottie },
    data(){
        return {
            defaultOptions: { animationData: animationData },
        }
    }
}
</script>
<template>
    <div class="noresult">
        <div class="text-center">
            <lottie class="avatar-xl" colors="primary:#121331,secondary:#08a88a" :options="defaultOptions" :height="75"
                :width="75" />
            <h5 class="mt-2">Sorry! No Result Found</h5>
            <p class="text-muted mb-0">
                No results found based on your search creterias.
            </p>
        </div>
    </div>
</template>