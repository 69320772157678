import {TOKEN, authRequest} from "../../helpers/auth.api"


export const state = {
    user: null,
    isLoggedIn: false,
}

export const mutations = {
    loginSuccess(state, user) {
        state.user = user;
        window.localStorage.setItem(TOKEN, user.token)
        state.isLoggedIn = true;
      },
      logout(state) {
        state.user = null;
        state.isLoggedIn = false;
        window.localStorage.removeItem(TOKEN);
        authRequest.defaults.headers.Authorization = '';

      },
      updateUser(state, user) {
        state.user = user;
      },

}

export const actions = {
    login({ commit },  data ) {
       commit("loginSuccess", data);
      },
      logout({ commit }) {
        commit('logout');
      },
      updateUser({ commit }, user  ){
        commit('updateUser',user);

      },
}

