<script>
import innerMenu from "@/components/inner-menu.vue"
import { accountMethods } from "@/state/helpers"
export default {

    components: { innerMenu },

    computed:{
        accounts:{
            get(){
                return this.$store.state.account.accounts
            },
        },
        selectedAccount:{
            get(){
                return this.$store.state.account.account
            },
            set(account){
                this.setAccount(account);
            }
        },
    },

    methods: {
        ...accountMethods,
        initFullScreen() {
            document.body.classList.toggle("fullscreen-enable");
            if (
                !document.fullscreenElement &&
                /* alternative standard method */
                !document.mozFullScreenElement &&
                !document.webkitFullscreenElement
            ) {
                // current working methods
                if (document.documentElement.requestFullscreen) {
                    document.documentElement.requestFullscreen();
                } else if (document.documentElement.mozRequestFullScreen) {
                    document.documentElement.mozRequestFullScreen();
                } else if (document.documentElement.webkitRequestFullscreen) {
                    document.documentElement.webkitRequestFullscreen(
                        Element.ALLOW_KEYBOARD_INPUT
                    );
                }
            } else {
                if (document.cancelFullScreen) {
                    document.cancelFullScreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                }
            }
        },
        toggleDarkMode() {
            
            if (document.documentElement.getAttribute("data-layout-mode") == "dark") {
                localStorage.setItem("layoutMode","light")
                document.documentElement.setAttribute("data-layout-mode", "light");
            } else {
                document.documentElement.setAttribute("data-layout-mode", "dark");
                localStorage.setItem("layoutMode","dark")
            }
        }
    },


}
</script>

<template>
    <header id="page-topbar" class="">
        <div class="layout-width">
            <div class="navbar-header shadow-none">
                <div class="d-flex">
                    <innerMenu class="header-item" />
                </div>

                <div class="d-flex align-items-center">

                    <!------------------------------------ Full Screen ---------------------------->
                    <div class="ms-1 header-item d-none d-sm-flex">
                        <b-button type="button" variant="ghost-secondary"
                            class="btn-icon btn-topbar rounded-circle shadow-none" data-toggle="fullscreen"
                            @click="initFullScreen">
                            <i class="bx bx-fullscreen fs-22"></i>
                        </b-button>
                    </div>

                    <div class="ms-1 header-item d-none d-sm-flex">
                        <b-button type="button" variant="ghost-secondary"
                            class="btn-icon btn-topbar rounded-circle light-dark-mode shadow-none"
                            @click="toggleDarkMode">
                            <i class="bx bx-moon fs-22"></i>
                        </b-button>
                    </div>

                    <!------------------------------------- Accounts ------------------------------>
                    <div class="dropdown ms-sm-3 header-item ">
                        <button type="button" class="btn shadow-none" id="page-header-user-dropdown"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="d-flex align-items-center">
                                {{selectedAccount.account_name}}
                            </span>
                        </button>
                        <div v-if="accounts" class="dropdown-menu dropdown-menu-end">
                            <template v-for="account,i in accounts.filter(el=> el.marketplaces.length>0)" :key="i">
                            <div class="dropdown-item cursor-pointer" :class="{'active':account==selectedAccount}" @click="setAccount(account)" >                                    
                                <span class="align-middle">{{account.account_name}}</span>
                            </div>            
                            </template>
                        </div>
                    </div>

                    <!------------------------------------- User Menu ------------------------------>
                    <div class="dropdown ms-sm-3 header-item topbar-user">
                        <button type="button" class="btn shadow-none" id="page-header-user-dropdown"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="d-flex align-items-center">
                                <i class="mdi mdi-cog-outline text-muted fs-24 align-middle me-1"></i>
                            </span>
                        </button>
                        <div class="dropdown-menu dropdown-menu-end">

                            <router-link class="dropdown-item" to="/user-info"><i
                                    class="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                                <span class="align-middle">User Info</span>
                            </router-link>

                            <router-link class="dropdown-item" to="/connections"><i
                                    class="mdi mdi-lan-connect text-muted fs-16 align-middle me-1"></i>
                                <span class="align-middle">Connections</span>
                            </router-link>
                            <router-link class="dropdown-item" to="/mails"><i
                                    class="mdi mdi-email-variant text-muted fs-16 align-middle me-1"></i>
                                <span class="align-middle">Mails</span>
                            </router-link>


                            <b-link class="dropdown-item" href="/login"><i
                                    class="mdi mdi-logout fs-16 align-middle me-1"></i>
                                <span class="align-middle" data-key="t-logout">Logout</span>
                            </b-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>

</template>