
export const state = {
    selected_products: null
}

export const mutations = {
    SET_PRODUCTS(state, selected_products) {
        state.selected_products = selected_products;
      },

}

export const actions = {
    setProducts({ commit },  selected_products ) {
       commit("SET_PRODUCTS", selected_products);
      },
}