
export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/auth/login.vue"),
    meta: {
      title: "Login",authRequired: false,
    },
  },
  {
    path: "/signal-station",
    name: "SignalStation",
    component: () => import("@/views/signal_station/SignalStation.vue"),
    meta: {
      title: "Signal Station",authRequired: true,
    },
    children: [
      {
        path: 'signals',
        name:'Signals',
        component: () => import('@/views/signal_station/subpages/Signals.vue'),
        meta: {
          title: "Signals", authRequired: true,
        },
      },
    ]

  },
  { path: '/', redirect: '/dashboard/overview' },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("@/views/dashboard/dashboard.vue"),
    meta: {
      title: "Dashboard",authRequired: true,
    },
    children: [
      {
        path: 'account-health',
        name:'AccountHealth',
        component: () => import('@/views/dashboard/subpages/AccountHealth.vue'),
        meta: {
          title: "Signals", authRequired: true,
        },
      },
      {
        path: 'overview',
        name:'Overview',
        component: () => import('@/views/dashboard/subpages/Overview.vue'),
        meta: {
          title: "Overview", authRequired: true,
        },
      },
    ],
  },
  {
    path: "/product-hunt",
    name: "ProductHunt",
    component: () => import("@/views/product_hunt/ProductHunt.vue"),
    meta: {
      title: "Product Hunt",authRequired: true,
    },
    children: [
      {
        path: 'amz-search',
        name:'AmzSearch',
        component: () => import('@/views/product_hunt/subpages/AmzSearch.vue'),
        meta: {
          title: "AmzSearch", authRequired: true,
        },
      },
      {
        path: 'keepa-search',
        name:'KeepaSearch',
        component: () => import('@/views/product_hunt/subpages/KeepaSearch.vue'),
        meta: {
          title: "KeepaSearch", authRequired: true,
        },
      },
      {
        path: 'tasks',
        name:'Tasks',
        component: () => import('@/views/product_hunt/subpages/Tasks.vue'),
        meta: {
          title: "Tasks", authRequired: true,
        },
      },
      
      {
        path: 'results',
        name:'Results',
        //props: route => ({ id: route.query.id }),

        component: () => import('@/views/product_hunt/subpages/Results.vue'),
        meta: {
          title: "Results", authRequired: true,
        },
      },

    ]
  },

  {
    path: "/product-hunt-light",
    name: "ProductHuntLight",
    component: () => import("@/views/product_hunt_light/ProductHuntLight.vue"),
    meta: {
      title: "Product Hunt Light",authRequired: true,
    },
    children: [
      {
        path: 'tasks-light',
        name:'TasksLight',
        component: () => import('@/views/product_hunt_light/subpages/MainPage.vue'),
        meta: {
          title: "Tasks", authRequired: true,
        },
      },
    ]
  },

  {
    path: "/boxmonitor_v1",
    name: "boxmonitor_V1",
    component: () => import("@/views/boxmonitor/BoxMonitorPage.vue"),
    meta: {
      title: "BoxMonitor",authRequired: true,
    },

  },
  {
    path: "/boxmonitor",
    name: "BoxMonitor",
    component: () => import("@/views/boxmonitor_v2/BoxMonitorPage.vue"),
    meta: {
      title: "BoxMonitor",authRequired: true,
    },
    children: [
      {
        path: 'offers',
        name:'Offers',
        component: () => import('@/views/boxmonitor_v2/subpages/Offers.vue'),
        meta: {
          title: "Offers", authRequired: true,
        },
      },
      {
        path: 'offers-old',
        name:'OffersOld',
        component: () => import('@/views/boxmonitor_v2/subpages/OffersOld.vue'),
        meta: {
          title: "Offers Old", authRequired: true,
        },
      },
      {
        path: 'products',
        name:'Products',
        component: () => import('@/views/boxmonitor_v2/subpages/Products.vue'),
        meta: {
          title: "Products", authRequired: true,
        },
      },
    ]
  },

  {
    path: "/reports",
    name: "Reports",
    component: () => import("@/views/reports/ProductsPage.vue"),
    meta: {
      title: "Reports",authRequired: true,
    },
    children: [
      {
        path: 'inventory',
        name:'Inventory',
        component: () => import('@/views/reports/subpages/Inventory.vue'),
        meta: {
          title: "Inventory", authRequired: true,
        },
      },
      {
        path: 'fbaInbound',
        name:'FBAInbound',
        component: () => import('@/views/reports/subpages/FBAInbound.vue'),
        meta: {
          title: "FBA Inbound", authRequired: true,
        },
      },

      {
        path: 'inboundRecievingDiffs',
        name:'InboundRecievingDiffs',
        component: () => import('@/views/reports/subpages/InboundRecievingGaps.vue'),
        meta: {
          title: "Inbound Recieving Diffs", authRequired: true,
        },
      },
    ]
  },

  {
    path: "/seller-spy",
    name: "SellerSpy",
    component: () => import("@/views/seller_spy/SellerSpy.vue"),
    meta: {
      title: "Seller Spy",authRequired: true,
    },
    children: [
      {
        path: 'in-my-boxes',
        name:'InMyBoxes',
        component: () => import('@/views/seller_spy/subpages/InMyBoxes.vue'),
        meta: {
          title: "InvenInMyBoxestory", authRequired: true,
        },
      },
      {
        path: 'sellers',
        name:'Sellers',
        component: () => import('@/views/seller_spy/subpages/Sellers.vue'),
        meta: {
          title: "Sellers", authRequired: true,
        },
      },
    ]
  },

  {
    path: "/user-info",
    name: "userInfo",

    component: () => import("@/views/settings/UserInfoPage.vue"),
    meta: {
      title: "User Info", authRequired: true,
    },
  },

  {
    // path: "*",
    path: "/500",
    name: "InternalError",
    component: () => import("@/views/account/errors/500.vue"),
    meta: {
      title: "Internal Error", authRequired: false,
    },

  },

  {
    // path: "*",
    path: "/connections",
    name: "Connections",
    component: () => import("@/views/connections/ConnectionsPage.vue"),
    meta: {
      title: "Connections", authRequired: true,
    },

  },

  
  {
    // path: "*",
    path: "/mails",
    name: "Mails",
    component: () => import("@/views/mails/MailList.vue"),
    meta: {
      title: "Connections", authRequired: true,
    },

  },

  {
    path: "/product-details",
    name: "ProductDetails",
    component: () => import("@/views/product_hunt/subpages/ProductDetails.vue"),
    meta: {
      title: "Product Details", authRequired: true,
    },

  },
  
  {
    path: "/business",
    name: "Business",
    component: () => import("@/views/business/business.vue"),
    meta: {title: "Business", authRequired: true,},
    children: [

      {
        path: 'purchases',
        name:'Purchases',
        component: () => import('@/views/business/subpages/Purchases.vue'),
        meta: {
          title: "Business", authRequired: true,
        },
      },
      {
        path: 'inboxes',
        name:'Inboxes',
        component: () => import('@/views/business/subpages/Inboxes.vue'),
        meta: {
          title: "Business", authRequired: true,
        },
      },
    ]
  },

  {
    // path: "*",
    path: "/loading/:pathMatch(.*)",
    name: "Loading",
    props:true,
    component: () => import("@/views/account/loading/Loading.vue"),
    meta: {
      title: "Loading", authRequired: false,
    },
  },

  {
    path: '/auth/handle:pathMatch(.*)',
    name: 'Auth',
    meta: {
      title: "Handle", authRequired: false,
    },
    component: () => import('@/views/connections/AuthPage.vue')
  },

  {
    path: '/auth/gmail:pathMatch(.*)',
    name: 'AuthGmail',
    meta: {
      title: "MailAuth", authRequired: true,
    },
    component: () => import('@/views/mails/MailAuth.vue')
  },
  {
    // path: "*",
    path: "/:catchAll(.*)?",
    name: "notFound",
    component: () => import("@/views/account/errors/404.vue"),
    meta: {
      title: "Not found", authRequired: false,
    },

  },



  
];