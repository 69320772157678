<script>

export default{
    props: {show:Boolean, text:{type:Boolean, default:false}, icon:{type:Boolean, default:false}},
    
}

</script>

<template>
    <div>
        <b-overlay :show="show" >
            <slot/>
            <template v-if="!icon" #overlay>

              <b-spinner variant="primary"></b-spinner>
                <!--div class="spinner">
                    <div class="bounce1 m-1"></div>
                    <div class="bounce2 m-1"></div>
                    <div class="bounce3 m-1"></div>
                </div-->
            </template>

            <template v-else #overlay>
              <div class="text-center" style="margin-top:50px">
                <b-spinner class="mt-4" variant="primary"></b-spinner>
                <p id="cancel-label">{{('Please-wait')}}</p>
              </div>
            </template>

        </b-overlay>
    </div>
</template>

<style scoped>

.spinner {
  margin: 10px auto 0;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 12px;
  height: 12px;
  background-color: #ff4500;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}

</style>