
export const state = {
    selected_product_search_job: null
}

export const mutations = {
    SET_PRODUCT_SEARCH_QUERY(state, selected_product_search_job) {
        state.selected_product_search_job = selected_product_search_job;
      },

}

export const actions = {
    setProductSearchQuery({ commit },  selected_product_search_job ) {
       commit("SET_PRODUCT_SEARCH_QUERY", selected_product_search_job);
      },
}