/* eslint-disable no-unused-vars */
const dayjs = require('dayjs')
const format = "YYYY-MM-DD"

const updateLocale = require('dayjs/plugin/updateLocale')
dayjs.extend(updateLocale)

require(`dayjs/locale/en`);
require(`dayjs/locale/ru`);
require(`dayjs/locale/tr`);
require(`dayjs/locale/it`);
require(`dayjs/locale/es`);
require(`dayjs/locale/de`);
require(`dayjs/locale/fr`);

dayjs.updateLocale('en', {weekStart: 1})
dayjs.updateLocale('tr', {weekStart: 1})
dayjs.updateLocale('ru', {weekStart: 1})
dayjs.updateLocale('it', {weekStart: 1})
dayjs.updateLocale('es', {weekStart: 1})
dayjs.updateLocale('de', {weekStart: 1})
dayjs.updateLocale('fr', {weekStart: 1})



export const period4 = [
    {
        isCustom:false,
        value : 'TODAY_LASTDAY_LASTWEEK_2WEEKSAGO',
        label : 'TODAY_LASTDAY_LASTWEEK_2WEEKSAGO',
        partition:['today','lastday','lastWeek', 'twoWeekAgo','threeWeekAgo'],
        dateStr : ()=>{
            const today = dayjs().format(format)
            const lastday = dayjs().subtract(1,'day').format(format)
            const lastWeekStart = dayjs().subtract(7, 'day').startOf('week').format(format)
            const lastWeekEnd = dayjs().subtract(7, 'day').endOf('week').format(format)
            const twoWeekAgoStart = dayjs().subtract(14, 'day').startOf('week').format(format)
            const twoWeekAgoEnd = dayjs().subtract(14, 'day').endOf('week').format(format)
            const threeWeekAgoStart = dayjs().subtract(21, 'day').startOf('week').format(format)
            const threeWeekAgoEnd = dayjs().subtract(21, 'day').endOf('week').format(format)
            return[
                {today:{startDate:today, endDate:today}},
                {lastday:{startDate:lastday, endDate:lastday}},
                {lastWeek:{startDate:lastWeekStart, endDate:lastWeekEnd}},
                {twoWeekAgo:{startDate:twoWeekAgoStart, endDate:twoWeekAgoEnd}},
                {threeWeekAgo:{startDate:threeWeekAgoStart, endDate:threeWeekAgoEnd}}
            ] 
        }
    },

    {
        isCustom:false,
        value : 'TODAY_LASTDAY_THISMONTH_LASTMONTH',
        label : 'TODAY_LASTDAY_THISMONTH_LASTMONTH',
        partition:['today','lastday','thisMonth', 'lastMonth','twoMonthAgo'],
        dateStr : ()=>{
            const today = dayjs().format(format)
            const lastday = dayjs().subtract(1,'day').format(format)
            const thisMonthStart = dayjs().startOf('month').format(format)
            const thisMonthEnd = dayjs().format(format)
            const lastMonthStart = dayjs().subtract(1, 'month').startOf('month').format(format)
            const lastMonthEnd = dayjs().subtract(1, 'month').endOf('month').format(format)
            const twoMonthStart = dayjs().subtract(2, 'month').startOf('month').format(format)
            const twoMonthEnd = dayjs().subtract(2, 'month').endOf('month').format(format)
            return[
                {today:{startDate:today, endDate:today}},
                {lastday:{startDate:lastday, endDate:lastday}},
                {thisMonth:{startDate:thisMonthStart, endDate:thisMonthEnd}},
                {lastMonth:{startDate:lastMonthStart, endDate:lastMonthEnd}},
                {twoMonthAgo:{startDate:twoMonthStart, endDate:twoMonthEnd}},
            ] 
        }
    },

    {
        isCustom:false,
        value : 'LASTFIVEDAYS',
        label : 'LASTFIVEDAYS',
        partition:['today','lastday','twodaysago', 'thredaysago','fourdaysago'],
        dateStr : ()=>{
            const today = dayjs().format(format)
            const lastday = dayjs().subtract(1,'day').format(format)
            const twodaysago = dayjs().subtract(2,'day').format(format)
            const thredaysago = dayjs().subtract(3,'day').format(format)
            const fourdaysago = dayjs().subtract(4,'day').format(format)
            

            return[
                {today:{startDate:today, endDate:today}},
                {lastday:{startDate:lastday, endDate:lastday}},
                {twodaysago:{startDate:twodaysago, endDate:twodaysago}},
                {thredaysago:{startDate:thredaysago, endDate:thredaysago}},
                {fourdaysago:{startDate:fourdaysago, endDate:fourdaysago}},
            ] 
        }
    },

    {
        isCustom:false,
        value : 'LAST5WEEKS',
        label : 'LAST5WEEKS',
        partition:['thisWeek','lastWeek','twoWeekAgo', 'threWeekAgo','fourWeekAgo'],
        dateStr : ()=>{
            const thisWeekStart     = dayjs().startOf('week').format(format)
            const thisWeekEnd       = dayjs().format(format)
            const lastWeekStart     = dayjs().subtract(7, 'day').startOf('week').format(format)
            const lastWeekEnd       = dayjs().subtract(7, 'day').endOf('week').format(format)
            const twoWeekAgoStart   = dayjs().subtract(14, 'day').startOf('week').format(format)
            const twoWeekAgoEnd     = dayjs().subtract(14, 'day').endOf('week').format(format)
            const threWeekAgoStart   = dayjs().subtract(21, 'day').startOf('week').format(format)
            const threWeekAgoEnd     = dayjs().subtract(21, 'day').endOf('week').format(format)
            const fourWeekAgoStart   = dayjs().subtract(28, 'day').startOf('week').format(format)
            const fourWeekAgoEnd     = dayjs().subtract(28, 'day').endOf('week').format(format)

            return[
                {thisWeek:{startDate:thisWeekStart, endDate:thisWeekEnd}},
                {lastWeek:{startDate:lastWeekStart, endDate:lastWeekEnd}},
                {twoWeekAgo:{startDate:twoWeekAgoStart, endDate:twoWeekAgoEnd}},
                {threWeekAgo:{startDate:threWeekAgoStart, endDate:threWeekAgoEnd}},
                {fourWeekAgo:{startDate:fourWeekAgoStart, endDate:fourWeekAgoEnd}},
            ] 
        }
    },
    {
        isCustom:false,
        value : 'LAST5MONTHS',
        label : 'LAST5MONTHS',
        partition:['thismonth','lastMonth','twoMonthsAgo', 'threMonthsAgo','fourMonthsAgo'],
        dateStr : ()=>{
            const thisMonthStart = dayjs().startOf('month').format(format)
            const thisMonthEnd = dayjs().format(format)
            const lastMonthStart = dayjs().subtract(1, 'month').startOf('month').format(format)
            const lastMonthEnd = dayjs().subtract(1, 'month').endOf('month').format(format)
            const twoMonthStart = dayjs().subtract(2, 'month').startOf('month').format(format)
            const twoMonthEnd = dayjs().subtract(2, 'month').endOf('month').format(format)
            const threMonthStart = dayjs().subtract(3, 'month').startOf('month').format(format)
            const threMonthEnd = dayjs().subtract(3, 'month').endOf('month').format(format)
            const fourMonthStart = dayjs().subtract(4, 'month').startOf('month').format(format)
            const fourMonthEnd = dayjs().subtract(4, 'month').endOf('month').format(format)

            return[
                {thismonth:{startDate:thisMonthStart, endDate:thisMonthEnd}},
                {lastMonth:{startDate:lastMonthStart, endDate:lastMonthEnd}},
                {twoMonthsAgo:{startDate:twoMonthStart, endDate:twoMonthEnd}},
                {threMonthsAgo:{startDate:threMonthStart, endDate:threMonthEnd}},
                {fourMonthsAgo:{startDate:fourMonthStart, endDate:fourMonthEnd}},
            ] 
        }
    },
]

export const period3 = [
    {
        isCustom: false,
        value: 'TODAY',
        label: 'Today',
        partition: ['selectedPeriod', 'previousPeriod'],
        date: () => {
            const today = dayjs().format(format);
            return { startDate: today, endDate: today };
        },
        dateStr: () => {
            const today = dayjs().format(format);
            const yesterday = dayjs().subtract(1, 'day').format(format);
    
            return [
                { selectedPeriod: { startDate: today, endDate: today } },
                { previousPeriod: { startDate: yesterday, endDate: yesterday } },
            ];
        },
    },
    {
        isCustom: false,
        value : 'THISMONTH',
        label : 'This Month',
        partition:['selectedPeriod','previousPeriod'],
        date : ()=>{
            const thisMonthStart = dayjs().startOf('month').format(format)
            const thisMonthEnd = dayjs().format(format)
            return {startDate:thisMonthStart, endDate:thisMonthEnd}
        },
        dateStr : ()=>{
            const currentDate = dayjs();
        const dayOfMonth = currentDate.date();

        const selectedPeriodStartDate = currentDate.date(1).format(format);
        const selectedPeriodEndDate = currentDate.date(dayOfMonth).format(format);

        const previousPeriodStartDate = currentDate.subtract(1, 'month').date(1).format(format);
        const previousPeriodEndDate = currentDate.subtract(1, 'month').date(dayOfMonth).format(format);

        return [
            { selectedPeriod: { startDate: selectedPeriodStartDate, endDate: selectedPeriodEndDate } },
            { previousPeriod: { startDate: previousPeriodStartDate, endDate: previousPeriodEndDate } },
        ];
        }
    },
    {
        isCustom: false,
        value: 'YESTERDAY',
        label: 'Yesterday',
        partition: ['selectedPeriod', 'previousPeriod'],
        date: () => {
            const yesterday = dayjs().subtract(1, 'day').format(format);
            return { startDate: yesterday, endDate: yesterday };
        },
        dateStr: () => {
            const yesterday = dayjs().subtract(1, 'day').format(format);
            const twoDaysAgo = dayjs().subtract(2, 'day').format(format);
    
            return [
                { selectedPeriod: { startDate: yesterday, endDate: yesterday } },
                { previousPeriod: { startDate: twoDaysAgo, endDate: twoDaysAgo } },
            ];
        },
    },    
    {
        isCustom: false,
        value: 'LASTMONTH',
        label: 'Last Month',
        partition: ['selectedPeriod', 'previousPeriod'],
        date: () => {
            const lastMonthStart = dayjs().subtract(1, 'month').startOf('month').format(format);
            const lastMonthEnd = dayjs().subtract(1, 'month').endOf('month').format(format);
            return { startDate: lastMonthStart, endDate: lastMonthEnd };
        },
        dateStr: () => {
            const lastMonthStart = dayjs().subtract(1, 'month').startOf('month').format(format);
            const lastMonthEnd = dayjs().subtract(1, 'month').endOf('month').format(format);
    
            const previousMonthStart = dayjs().subtract(2, 'month').startOf('month').format(format);
            const previousMonthEnd = dayjs().subtract(2, 'month').endOf('month').format(format);
    
            return [
                { selectedPeriod: { startDate: lastMonthStart, endDate: lastMonthEnd } },
                { previousPeriod: { startDate: previousMonthStart, endDate: previousMonthEnd } },
            ];
        }
    }, 
    {
        isCustom: false,
        value: 'LASTWEEK',
        label: 'Last Week',
        partition: ['selectedPeriod', 'previousPeriod'],
        date: () => {
            const lastWeekStart = dayjs().subtract(7, 'day').startOf('week').format(format);
            const lastWeekEnd = dayjs().subtract(7, 'day').endOf('week').format(format);
            return { startDate: lastWeekStart, endDate: lastWeekEnd };
        },
        dateStr: () => {
            const lastWeekStart = dayjs().subtract(7, 'day').startOf('week').format(format);
            const lastWeekEnd = dayjs().subtract(7, 'day').endOf('week').format(format);
    
            const previousWeekStart = dayjs().subtract(14, 'day').startOf('week').format(format);
            const previousWeekEnd = dayjs().subtract(14, 'day').endOf('week').format(format);
    
            return [
                { selectedPeriod: { startDate: lastWeekStart, endDate: lastWeekEnd } },
                { previousPeriod: { startDate: previousWeekStart, endDate: previousWeekEnd } },
            ];
        }
    },   
    {
        isCustom: false,
        value: 'LAST90DAYS',
        label: 'Last 90 Days',
        partition: ['selectedPeriod', 'previousPeriod'],
        date: () => {
            const last90DaysStart = dayjs().subtract(90, 'day').format(format);
            const last90DaysEnd = dayjs().format(format);
            return { startDate: last90DaysStart, endDate: last90DaysEnd };
        },
        dateStr: () => {
            const last90DaysStart = dayjs().subtract(90, 'day').format(format);
            const last90DaysEnd = dayjs().format(format);
    
            const previous90DaysStart = dayjs().subtract(180, 'day').format(format);
            const previous90DaysEnd = dayjs().subtract(91, 'day').format(format);
    
            return [
                { selectedPeriod: { startDate: last90DaysStart, endDate: last90DaysEnd } },
                { previousPeriod: { startDate: previous90DaysStart, endDate: previous90DaysEnd } },
            ];
        },
    },
    {
        isCustom: false,
        value: 'LAST30DAYS',
        label: 'Last 30 Days',
        partition: ['selectedPeriod', 'previousPeriod'],
        date: () => {
            const last30DaysStart = dayjs().subtract(30, 'day').format(format);
            const last30DaysEnd = dayjs().format(format);
            return { startDate: last30DaysStart, endDate: last30DaysEnd };
        },
        dateStr: () => {
            const last30DaysStart = dayjs().subtract(30, 'day').format(format);
            const last30DaysEnd = dayjs().format(format);
    
            const previous30DaysStart = dayjs().subtract(60, 'day').format(format);
            const previous30DaysEnd = dayjs().subtract(31, 'day').format(format);
    
            return [
                { selectedPeriod: { startDate: last30DaysStart, endDate: last30DaysEnd } },
                { previousPeriod: { startDate: previous30DaysStart, endDate: previous30DaysEnd } },
            ];
        },
    },
    {
        isCustom: false,
        value: 'THISYEAR',
        label: 'This Year',
        partition: ['thisyear'],
        date: () => {
            const thisYearStart = dayjs().startOf('year').format(format);
            const thisYearEnd = dayjs().format(format);
            return { startDate: thisYearStart, endDate: thisYearEnd };
        },
        dateStr: () => {
            const currentDate = dayjs();
            const year = currentDate.year();
    
            const selectedPeriodStartDate = currentDate.year(year).startOf('year').format(format);
            const selectedPeriodEndDate = currentDate.year(year).format(format);
    
            const previousPeriodStartDate = currentDate.year(year - 1).startOf('year').format(format);
            const previousPeriodEndDate = currentDate.year(year - 1).endOf('year').format(format);
    
            return [
                { selectedPeriod: { startDate: selectedPeriodStartDate, endDate: selectedPeriodEndDate } },
                { previousPeriod: { startDate: previousPeriodStartDate, endDate: previousPeriodEndDate } },
            ];
        }    
    },
]

export const period2 =[
    {
        isCustom:false,
        value : 'THIS_YEAR_MONTHLY',
        label : 'THIS_YEAR_MONTHLY',
        
        dateStr : ()=>{
            const currentMonth = dayjs().month()
            let months =  [
                "January", "February", "March", "April", "May", "June", "July",
                "August", "September", "October", "November", "December"
              ]

              let partition = []
            let result = []
            for (let i = 0; i <= 11; i++) {
                result.push({ [months[dayjs().subtract(i, 'month').month()]] : {
                    startDate: dayjs().subtract(i,'month').startOf('month').format(format),
                    endDate: i==currentMonth ? dayjs().format(format) : dayjs().subtract(i, 'month').endOf('month').format(format)}})
                partition.push(months[dayjs().subtract(i, 'month').month()]) 
            }
            return {result: result, partition:partition}    
    }
},

{
    isCustom:false,
    value : 'LAST_3_MONTHS_WEEKLY',
    label : 'LAST_3_MONTHS_WEEKLY',
    
    dateStr : ()=>{
        let startWeek = dayjs().subtract(3,'month').week()
        let endWeek = dayjs().week()

        let partition = []
        let result = []
        for (let i = 0; i <= 12; i++) {
            result.push({ [`week${dayjs().subtract(i, 'week').week()}`] : {
                
                startDate: dayjs().subtract(i, 'week').startOf('week').format(format),
                endDate: i==0 ? dayjs().format(format) : dayjs().subtract(i,'week').endOf('week').format(format)}})

            partition.push(`week${dayjs().subtract(i, 'week').week()}`) 
        }
        return {result: result, partition:partition}    
}
},

{
isCustom:false,
value : 'LAST_30_DAYS_DAILY',
label : 'LAST_30_DAYS_DAILY',

dateStr : ()=>{
    let partition = []
    let result = []
    for (let i = 0; i <= 30; i++) {
        result.push({ [dayjs().subtract(i,'day').format(format)] : {startDate: dayjs().subtract(i,'day').format(format), endDate: dayjs().subtract(i,'day').format(format)}})
        partition.push(dayjs().subtract(i,'day').format(format)) 
    }
    return {result: result, partition:partition}    
}
},


]


export const period1 = [
    {
        isCustom:false,
        value : 'LAST30',
        label : 'Last 30 Days',
        partition:['last30'],
        date : ()=>{
            const start = dayjs().subtract(30, 'day').format(format)
            const end = dayjs().format(format)
            return {startDate:start, endDate:end}
        },
        dateStr : ()=>{
            const start = dayjs().subtract(30, 'day').format(format)
            const end = dayjs().format(format)

            return[            
                {last30:{startDate:start, endDate:end}},
            ] 
        }
    },
    {
        isCustom:false,
        value : 'THISYEAR',
        label : 'This Year',
        partition:['thisyear'],
        date : ()=>{
            const thisYearStart = dayjs().startOf('year').format(format)
            const thisYearEnd = dayjs().format(format)
            return {startDate:thisYearStart, endDate:thisYearEnd}
        },
        dateStr : ()=>{
            const thisYearStart = dayjs().startOf('year').format(format)
            const thisYearEnd = dayjs().format(format)

            return[            
                {thisYear:{startDate:thisYearStart, endDate:thisYearEnd}},
            ] 
        }
    },
    {
        isCustom:false,
        value : 'THISMONTH',
        label : 'This Month',
        partition:['thismonth'],
        date : ()=>{
            const thisMonthStart = dayjs().startOf('month').format(format)
            const thisMonthEnd = dayjs().format(format)
            return {startDate:thisMonthStart, endDate:thisMonthEnd}
        },
        dateStr : ()=>{
            const thisMonthStart = dayjs().startOf('month').format(format)
            const thisMonthEnd = dayjs().format(format)

            return[            
                {thisMonth:{startDate:thisMonthStart, endDate:thisMonthEnd}},
            ] 
        }
    },

    {
        isCustom:false,
        value : 'LASTMONTH',
        label : 'Last Month',
        partition:['lastmonth'],
        date : ()=>{
            const lastMonthStart = dayjs().subtract(1, 'month').startOf('month').format(format)
            const lastMonthEnd = dayjs().subtract(1, 'month').endOf('month').format(format)

            return {startDate:lastMonthStart, endDate:lastMonthEnd}
            },
        dateStr : ()=>{
            const lastMonthStart = dayjs().subtract(1, 'month').startOf('month').format(format)
            const lastMonthEnd = dayjs().subtract(1, 'month').endOf('month').format(format)

            return[            
                {lastMonth:{startDate:lastMonthStart, endDate:lastMonthEnd}},
            ] 
        }
    },

    {
        isCustom:false,
        value : 'TODAY',
        label : 'Today',
        partition:['today'],
        date : ()=>{
            const today = dayjs().format(format)
            return {startDate:today, endDate:today}
        },
        dateStr : ()=>{
            const today = dayjs().format(format)
            return[            
                {today:{startDate:today, endDate:today}},
            ] 
        }
    },

    {
        isCustom:false,
        value : 'YESTERDAY',
        label : 'Yesterday',
        partition:['yesterday'],
        date : ()=>{
            const yesterday = dayjs().subtract(1,'day').format(format)
            return {startDate:yesterday, endDate:yesterday}
        },
        dateStr : ()=>{
            const yesterday = dayjs().subtract(1,'day').format(format)
            return[            
                {yesterday:{startDate:yesterday, endDate:yesterday}},
            ] 
        }
    },

    {
        isCustom:false,
        value : 'LASTWEEK',
        label : 'Last Week',
        partition:['lastweek'],
        date : ()=>{
            const lastWeekStart = dayjs().subtract(7, 'day').startOf('week').format(format)
            const lastWeekEnd = dayjs().subtract(7, 'day').endOf('week').format(format)
            return {startDate:lastWeekStart, endDate:lastWeekEnd}
        },
        dateStr : ()=>{
            const lastWeekStart = dayjs().subtract(7, 'day').startOf('week').format(format)
            const lastWeekEnd = dayjs().subtract(7, 'day').endOf('week').format(format)

            return[            
                {lastweek:{startDate:lastWeekStart, endDate:lastWeekEnd}},
            ] 
        }
    },

   
]

