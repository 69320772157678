
export const state = {
    product: null
}

export const mutations = {
    SET_PRODUCT(state, product) {
        state.product = product;
      },

}

export const actions = {
    setProduct({ commit },  product ) {
       commit("SET_PRODUCT", product);
      },
}