<template>
    <div v-if="loader">
      
      <loader object="#ff9633" color1="#ffffff" color2="#17fd3d" size="25" speed="2" bg="#343a40" objectbg="#999793" opacity="80" disableScrolling="false" name="loading"></loader>      
      </div>
</template>

<script>
import {emitter} from "../main"

export default {
  data() {
    return {
      loader: false
    };
  },
  created() {
    // "stopPreLoader" olayı için dinleyici
    emitter.on("stopPreLoader", () => {
      this.loader = false;
    
    });

    // "startPreLoader" olayı için dinleyici
    emitter.on("startPreLoader", () => {
      this.loader = true;
    });
  },
  // Bileşen yok edildiğinde olay dinleyicilerini kaldır
  beforeUnmount() {
    emitter.off("stopPreLoader");
    emitter.off("startPreLoader");
  }
};


</script>