
export const state = {
    selected_marketplace: null
}

export const mutations = {
    SET_MARKETPLACE(state, selected_marketplace) {
        state.selected_marketplace = selected_marketplace;
      },

}

export const actions = {
    setMarketplace({ commit },  selected_marketplace ) {
       commit("SET_MARKETPLACE", selected_marketplace);
      },
}