<script>
  import Menu from "@/components/menu.vue";
  import Footer from "@/components/footer";
  import NavBar from "@/components/navbar"
//  import RightBarWidgets from "@/components/right-bar-widgets.vue"
 // localStorage.setItem('hoverd',false);
  
  /**
   * Vertical layout
   */
  export default {
    components: {  Footer, Menu, NavBar },
    data() {
      return {
        isMenuCondensed: false,
      };
    },
    computed: {
    },
    created: () => {
      document.body.removeAttribute("data-layout", "horizontal");
      document.body.removeAttribute("data-topbar", "dark");
      document.body.removeAttribute("data-layout-size", "boxed");
    },
    methods: {
    },
    mounted() {


  
    },
  };
  </script>
  
  <template>
    <div id="layout-wrapper">
      <nav-bar/>
      <div>
        <!-- ========== Left Sidebar Start ========== -->
        <!-- ========== App Menu ========== -->
        <div class="app-menu navbar-menu position-fixed">
          <!-- LOGO -->
          <div class="navbar-brand-box">
            <!-- Dark Logo-->
            <router-link to="/" class="logo logo-dark">
              <span class="logo-sm">
                <img src="@/assets/images/logo-dark-text.png" alt="" height="22" />
              </span>
              <span class="logo-lg">
                <img src="@/assets/images/logo-dark-text.png" alt="" height="60" />
              </span>
            </router-link>
            <!-- Light Logo-->
            <router-link to="/" class="logo logo-light">
              <span class="logo-sm">
                <img src="@/assets/images/logo-light-text.png" alt="" height="22" />
              </span>
              <span class="logo-lg">
                <img src="@/assets/images/logo-light-text.png" alt="" height="60" />
              </span>
            </router-link>
            <button type="button" class="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover" id="vertical-hover" >
              <i class="ri-record-circle-line"></i>
            </button>
          </div>
  
          <!-- <SimpleBar id="scrollbar" class="h-100" ref="scrollbar"> -->
          <Menu></Menu>

          <!-- </SimpleBar> -->
          <div class="sidebar-background"></div>
        </div>
        <!-- Left Sidebar End -->
        <!-- Vertical Overlay-->
        <div class="vertical-overlay" id="overlay"></div>
      </div>
      <!-- ============================================================== -->
      <!-- Start Page Content here -->
      <!-- ============================================================== -->
  
      <div class="main-content">
        <div id="page-content" class="page-content">
          <!-- Start Content-->
         
          <b-container id="main-container" fluid>
            

            
            <slot />
          </b-container>
        </div>
        <Footer />
      </div>
      


      <notifications position="bottom center" />

      
    </div>
  </template>

  <style scoped>


</style>
