<script>
import Buybox from "@/views/boxmonitor_v2/componenets/Buybox.vue"
export default {
    props: {
        product: { type: Object, default: null },
        details: { type: Boolean, default: false },
        buybox:{type: Boolean, default: false}

    },

    components:{ Buybox },

    methods: {
        getUrl() {
            try {
                if (this.product.marketplace){
                return `${this.getMarketPlaceDetailsByID(this.product.marketplace).domainName}/dp/${this.product.asin}`}
                else return `${this.$store.state.marketplace.selected_marketplace.domainName}/dp/${this.product.asin}`
            }
            catch {
                return null
            }
        },

        clicked() {
            this.$emit('onClick', this.product)
        },
    },
}

</script>
<template>
    <div v-if="product" class="d-flex d-flex-inline gap-3 mb-n2">
        <img :src="product.imageURL" height="45" width="45" />
        <div class="w-100">
            <div class="d-flex justify-content-between">
            <p class="fw-bold">{{ product.sku ? product.sku : product.seller_sku }} / <a :href="getUrl()"
                    target="_blank">{{ product.asin }}</a> </p>
            <Buybox class="text-end" :item="product" />
            </div>
                    
            <VTooltip>
            <p class="mt-n3 mb-1 long-text" style="max-width: 150px;">{{ product.product_name ? product.product_name : product.item_name }} - <span
                    class="fw-bold">{{ product.brandName }}</span></p>
            <template #popper>
                <p class="p-2" style="max-width: 150px;">{{ product.product_name ? product.product_name : product.item_name }} - <span
                    class="fw-bold">{{ product.brandName }}</span></p>
            </template>
            </VTooltip>
            <p class="mb-1">
                <slot />
            </p>
            <b-button v-if="details" @click="clicked" variant="success" size="sm"
                class="badge badge-soft-success">Details</b-button>
        </div>
    </div>
</template>