import { mapState, mapGetters, mapActions } from 'vuex'

// ------------------------------ AUTH --------------------------------------------
export const authComputed = {
  ...mapState('user', {
    currentUser: (state) => state.user,
  }),
  ...mapGetters('auth', ['isLoggedIn']),
}

export const accountComputed = {
  //...mapState('account', {account: (state) => state.account,}),
  ...mapGetters('account', ['account']),
}

export const accountMethods = mapActions('account', ['setAccount','setAccounts' ])

export const marketplaceMethods = mapActions('marketplace', ['setMarketplace'])

export const productsMethods = mapActions('products', ['setProducts'])

export const shipmentsMethods = mapActions('shipments', ['setShipments'])

export const productDetailsMethods = mapActions('productDetails', ['setProduct'])

export const dateMethods = mapActions('date', ['setDate'])

export const authMethods = mapActions('user', ['login', 'logout'])

export const queries = mapActions('queries', ['setProductSearchQuery'])



