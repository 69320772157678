export const sellingPartnerMarketPlaceses = [
 
    {
      flag: require("@/assets/images/flags/us.svg"),
      marketPlaceID: "ATVPDKIKX0DER",
      value:"ATVPDKIKX0DER",
      country: "United States of America",
      URL:"https://sellercentral.amazon.com",
      countryCode:"US",
      sellingRegion:"NorthAmerica",
      domainName:"https://amazon.com",
      isBusiness:true,
      businessAuth:"https://www.amazon.com/b2b/abws/oauth",
      currencyCode:'USD'
    },
    {
      flag: require("@/assets/images/flags/ca.svg"),
      marketPlaceID: "A2EUQ1WTGCTBG2",
      value:"A2EUQ1WTGCTBG2",
      country: "Canada",
      URL:"https://sellercentral.amazon.ca",
      countryCode:"CA",
      sellingRegion:"NorthAmerica",
      domainName:"https://amazon.ca",
      isBusiness:true,
      businessAuth:"https://www.amazon.ca/b2b/abws/oauth",
      currencyCode:'CAD'
    },
    {
      flag: require("@/assets/images/flags/mx.svg"),
      marketPlaceID: "A1AM78C64UM0Y8",
      value:"A1AM78C64UM0Y8",
      country: "Mexico",
      URL:"https://sellercentral.amazon.com.mx",
      countryCode:"MX",
      sellingRegion:"NorthAmerica",
      domainName:"https://amazon.com.mx",
      isBusiness:false,
      currencyCode:'MXN'
    },
    {
      flag: require("@/assets/images/flags/br.svg"),
      marketPlaceID: "A2Q3Y263D00KWC",
      value:"A2Q3Y263D00KWC",
      country: "Brazil",
      URL:"https://sellercentral.amazon.com.br",
      countryCode:"BR",
      sellingRegion:"NorthAmerica",
      domainName:"https://amazon.com.br",
      isBusiness:false,
      currencyCode:'BRL'
    },
    {
      flag: require("@/assets/images/flags/spain.svg"),
      marketPlaceID: "A1RKKUPIHCS9HS",
      value:"A1RKKUPIHCS9HS",
      country: "Spain",
      URL:"https://sellercentral-europe.amazon.com",
      countryCode:"ES",
      sellingRegion:"Europe",
      domainName:"https://amazon.es",
      isBusiness:false,
      businessAuth:"https://amazon.es/b2b/abws/oauth",
      currencyCode:'EUR'
    },
    {
      flag: require("@/assets/images/flags/gb.svg"),
      marketPlaceID: "A1F83G8C2ARO7P",
      value:"A1F83G8C2ARO7P",
      country: "United Kingdom",
      URL:"	https://sellercentral-europe.amazon.com",
      countryCode:"UK",
      sellingRegion:"Europe",
      domainName:"https://amazon.co.uk",
      isBusiness:false,
      businessAuth:"https://www.amazon.co.uk/b2b/abws/oauth",
      currencyCode:'GBP'
    },
   {
      flag: require("@/assets/images/flags/fr.svg"),
      marketPlaceID: "A13V1IB3VIYZZH",
      value:"A13V1IB3VIYZZH",
      country: "France",
      URL:"	https://sellercentral-europe.amazon.com",
      countryCode:"FR",
      sellingRegion:"Europe",
      domainName:"https://amazon.fr",
      isBusiness:false,
      businessAuth:"https://www.amazon.fr/b2b/abws/oauth",
      currencyCode:'EUR'

    },
    {
      flag: require("@/assets/images/flags/de.svg"),
      marketPlaceID: "A1PA6795UKMFR9",
      value:"A1PA6795UKMFR9",
      country: "Germany",
      URL:"	https://sellercentral-europe.amazon.com",
      countryCode:"DE",
      sellingRegion:"Europe",
      domainName:"https://amazon.de",
      isBusiness:false,
      businessAuth:"https://amazon.de/b2b/abws/oauth",
      currencyCode:'EUR'

    },
  ]
  
    
  
    export function getMarketPlaceDetailsByID(marketPlaceID){
      let result = {}
      sellingPartnerMarketPlaceses.forEach(val=>{
        if (val.marketPlaceID==marketPlaceID){
          result =  val
        }
      }) 
  
      return result
    }

    export function getMarketPlaceDetailsByCountryCode(code){
      let result = {}
      sellingPartnerMarketPlaceses.forEach(val=>{
        if (val.countryCode==code){
          result =  val
        }
      }) 
  
      return result
    }


    export function marketplacesFromAccount(){
      let result = []
      if ('account' in localStorage){
        var markets = JSON.parse(localStorage.getItem('account')).marketplaces
      } else return []
      
      markets.forEach(marketplace=>{
        sellingPartnerMarketPlaceses.forEach(val=>{
          if (marketplace.countryCode == val.countryCode){
            val['sp_api_token_id'] = marketplace.sp_api_token_id
            result.push(val)
          }
        })
      })

      return result
    }
  
    export function marketplacesFromState(account){
      
      let result = []
      var markets = account.marketplaces
      
      markets.forEach(marketplace=>{
        sellingPartnerMarketPlaceses.forEach(val=>{
          if ((marketplace.countryCode == val.countryCode) && (marketplace.domainName.startsWith('www.amazon'))){
            val['sp_api_token_id'] = marketplace.sp_api_token_id
            result.push(val)
          }
        })
      })

      return result
    }
  