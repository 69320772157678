import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { authRequest, getHeader } from "@/helpers/auth.api.js";
import { getMarketPlaceDetailsByID } from "@/utils/amazon-marketplaces";
import { queries } from "@/state/helpers";

export default {
  methods: {
    ...queries,

    sortTable(n, tableId = "myTable", asc = true) {
      var table = document.getElementById(tableId);
      var tbody = table.tBodies[0];
      var rows = Array.from(tbody.rows);

      // Karşılaştırma fonksiyonu
      var comparer = (idx, asc) => (a, b) =>
        ((v1, v2) =>
          v1 !== "" && v2 !== "" && !isNaN(v1) && !isNaN(v2)
            ? v1 - v2
            : v1.toString().localeCompare(v2))(
          asc ? a.children[idx].innerText : b.children[idx].innerText,
          asc ? b.children[idx].innerText : a.children[idx].innerText
        );

      // Sıralama ve DOM'a geri ekleme
      rows.sort(comparer(n, asc)).forEach((tr) => tbody.appendChild(tr));
    },
    formatter(value) {
      var date = new Date(value);
      var formattedDate =
        date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate();
      return formattedDate;
    },

    checkSellerId(SellerId) {
      var flag = false;
      if (this.account) {
        this.account.sp_api_tokens.forEach((element) => {
          if (element.sellerId == SellerId) flag = true;
        });
      }

      return flag;
    },

    getMarketPlaceDetailsByID: getMarketPlaceDetailsByID,

    parseErrorMessage(err) {
      if (err.response.status == 422){
        return "Unprocessable Entity"
      }
      try {
        return (
          err.response.data.error ||
          err.response.data.detail ||
          err.response.statusText ||
          err
        );
      } catch {
        return "Encountered an error while processing";
      }
    },
    notify(type = "info", msg = "Hello") {
      if (type == "error") {
        let parsed_msg = this.parseErrorMessage(msg);
        toast(parsed_msg, {
          theme: "colored",
          type: type,
          autoClose: 2000,
          hideProgressBar: true,
          dangerouslyHTMLString: true,
        });
      } else {
        toast(msg, {
          theme: "colored",
          type: type,
          autoClose: 2000,
          hideProgressBar: true,
          dangerouslyHTMLString: true,
        });
      }
    },

    camelCase(snake_case) {
      return snake_case
        .replace(/(_\w)/g, (match) => " " + match[1].toUpperCase())
        .replace(/^./, (match) => match.toUpperCase());
    },
    camelCaseToTitle(str) {
      // Camel case ifadeyi boşluklarla ayırır ve title case'e çevirir.
      return str
        .replace(/([A-Z])/g, " $1") // Büyük harflerden önce boşluk ekler.
        .trim() // Başındaki ve sonundaki boşlukları siler.
        .toLowerCase() // Tüm harfleri küçük harfe çevirir.
        .split(" ") // Boşluk karakterine göre ayırarak diziye dönüştürür.
        .map(function (word) {
          return word.charAt(0).toUpperCase() + word.slice(1); // Her kelimenin ilk harfini büyük harf yapar.
        })
        .join(" "); // Kelimeleri boşluk ile birleştirerek string'e dönüştürür.
    },

    _toLowerCase(str) {
      return this.camelCase(str).toLowerCase();
    },

    _trim(str) {
      try {
        return str.replace(/\s+/g, " ").trim();
      } catch (e) {
        return str;
      }
    },

    formatCurrency(item) {
      try {
        const formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: item.currencyCode,
        });
        return formatter.format(item.amount);
      } catch (e) {
        try{
          const formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: item.CurrencyCode,
          });
          return formatter.format(item.Amount);

        }catch(e){
          return "-"
        }
        
      }
    },
    getLocal(isoString) {
      if (!isoString || isoString == "") {
        return null;
      }
      try{
      const utcDate = new Date(isoString);
      const localDate = new Date(
        utcDate.getTime() - utcDate.getTimezoneOffset() * 60000
      );

      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: false,
      };

      return localDate.toLocaleString(navigator.language, options);}
      catch{
        return null
      }
    },

    iso_to_local(isoString) {
      if (!isoString || isoString == "") {
        return null;
      }
      try{
      const utcDate = new Date(isoString);
      const localDate = new Date(
        utcDate.getTime() - utcDate.getTimezoneOffset()
      );

      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: false,
      };

      return localDate.toLocaleString(navigator.language, options);}
      catch{
        return null
      }
    },

    keepa_to_date(val) {
      const keepaStartOrdinal = new Date("2011-01-01T00:00:00Z");

      const date = new Date(keepaStartOrdinal.getTime() + val * 60000);

      return date.toLocaleString();
    },

   keepa_to_date_v2(val) {
      const keepaStartOrdinal = new Date("2011-01-01T00:00:00Z");
      const date = new Date(keepaStartOrdinal.getTime() + val * 60000);
      
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      
      return `${year}-${month}-${day}`;
    },
    

    debounce(func, wait, immediate) {
      let timeout;
      return function () {
        const context = this, args = arguments;
        const later = function () {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
      };
    },
  },

  computed: {},

  data() {
    return {
      authRequest: authRequest,
      getHeader: getHeader,
      account: this.$store.state.account.account,
    };
  },
};
