<script>
import { emitter } from "@/main"

export default {
    mounted() {
        emitter.on("setMenus", (buttons) => {
            this.buttons = buttons
            //this.active = this.$router.currentRoute._rawValue.name
        })
    },

    watch: {
      $route:{
        immediate: true,
        deep: true,
        handler(){ this.active = this.$router.currentRoute._rawValue.name}
      }
    },
    methods: {
      openSideBar(){
        document.getElementById('open-widgets-settings').click()
      },
        clickBtn(btn) {
            emitter.emit(`${btn}Click`)
            this.active = btn
        },
        camelCaseToTitle(str) {
      // Camel case ifadeyi boşluklarla ayırır ve title case'e çevirir.
      return str
        .replace(/([A-Z])/g, " $1") // Büyük harflerden önce boşluk ekler.
        .trim() // Başındaki ve sonundaki boşlukları siler.
        .toLowerCase() // Tüm harfleri küçük harfe çevirir.
        .split(" ") // Boşluk karakterine göre ayırarak diziye dönüştürür.
        .map(function (word) {
          return word.charAt(0).toUpperCase() + word.slice(1); // Her kelimenin ilk harfini büyük harf yapar.
        })
        .join(" "); // Kelimeleri boşluk ile birleştirerek string'e dönüştürür.
    },
    resetLayout(){
      emitter.emit("resetLayout")
    },
  },
    data() {
        return {
            buttons: null,
            active:null,
        }
    },
}
</script>
<template>
   <!----- btn-ghost-secondary -->
        <ul class="nav nav-tabs-custom border-0 card-header-tabs profile-nav gap-0 gap-lg-1 flex-grow-1 m-0" >
            <li  v-for="(btn,index) in buttons" :key="index" >
                <b-button :active="btn==active" type="button" size="sm" class="btn  btn-ghost-primary  shadow-none" @click="clickBtn(btn)" >
                  <span class="text-muted fs-14">{{camelCaseToTitle(btn)}}</span>
                </b-button>
              </li>
            <li v-if="buttons && buttons.includes('Overview') && active=='Overview'">
              <b-button 
                size="sm"
                type="button"
                class="btn  btn-ghost-primary  bg-info-subtle shadow-none"
                @click="openSideBar()"
               ><span class="text-muted fs-14">Customize</span></b-button>
            </li>
            <li v-if="buttons && buttons.includes('Overview') && active=='Overview'">
              <b-button 
                size="sm"
                type="button"
                class="btn  btn-ghost-primary  bg-danger-subtle shadow-none"
                @click="resetLayout()"
               ><span class="text-muted fs-14">Reset Layout</span></b-button>
            </li>
            </ul>
</template>