
export const state = {
    account: null,
    accounts:null,
}

export const mutations = {
    SET_ACCOUNT(state, account) {
        state.account = account;
        window.localStorage.setItem('account', JSON.stringify(account))
      },
      SET_ACCOUNTS(state, accounts) {
        state.accounts = accounts;
        state.account = accounts[0]
        window.localStorage.setItem('account', JSON.stringify(accounts[0]))
        
      },
}

export const actions = {
    setAccount({ commit },  account ) {
       commit("SET_ACCOUNT", account);
      },
    setAccounts({ commit },  accounts ) {
        commit("SET_ACCOUNTS", accounts);
       },
}