<!-- eslint-disable no-unused-vars -->
<script>

import Multiselect from "@vueform/multiselect";
import { marketplacesFromState, getMarketPlaceDetailsByCountryCode } from "@/utils/amazon-marketplaces"
import { marketplaceMethods } from "@/state/helpers"


export default {
  components: { Multiselect },

  mounted() {
    this.options = marketplacesFromState(this.selected_account)
    let selected_marketplace = this.$store.state.marketplace.selected_marketplace
    if (selected_marketplace) {
      this.$refs.multiselect.select(selected_marketplace)
    }
    else {
      if (this.selected_account.sp_api_tokens) {
        let default_marketplace = getMarketPlaceDetailsByCountryCode(this.selected_account.sp_api_tokens[0].default_marketplace)
        this.$refs.multiselect.select(default_marketplace)
      }
      else {
        let default_marketplace = getMarketPlaceDetailsByCountryCode(JSON.parse(localStorage.getItem('account')).sp_api_tokens[0].default_marketplace)
        this.$refs.multiselect.select(default_marketplace)
      }
    }
  },

  computed: {
    selected_account: {
      get() {
        return this.$store.state.account.account
      },
    }
  },

  watch: {
    selected_account() {
      this.options = marketplacesFromState(this.selected_account)
      try {
        let default_marketplace = this.selected_account.sp_api_tokens[0].default_marketplace ? getMarketPlaceDetailsByCountryCode(this.account.sp_api_tokens[0].default_marketplace) : {}
        this.$refs.multiselect.select(default_marketplace)
      }
      catch {
        this.$refs.multiselect.select(this.options[0])
      }
    },
  },

  methods: {
    ...marketplaceMethods,

    selected() {
      this.$emit("selectedMarketplace", this.model);
      this.setMarketplace(this.model)
    },
  },

  data() {
    return {
      options: [],

      model: {},
    };
  },
};
</script>

<template>
  <Multiselect data-dropdown-mode="filter" ref="multiselect" class="form-control" v-model="model"
    :close-on-select="true" :searchable="false" :create-option="true" :canClear="false" :canDeselect="false"
    :object="true" mode="single" :classes="{
      option:
        'form-control border-0 flex items-center justify-start box-border text-left cursor-pointer text-base leading-snug py-2 rounded-0 px-3',

    }" :options="options" @select="selected">
    <template v-slot:singlelabel="{ value }">
      <div class="multiselect-single-label">
        <img class="character-label-icon rounded" :src="value.flag" height="15" />
        &nbsp;&nbsp;{{ value.country }}
      </div>
    </template>

    <template v-slot:option="{ option }">
      <img class="character-option-icon rounded" :src="option.flag" height="15" /><span
        v-if="option.flag != null">&nbsp;&nbsp;</span> {{ option.country }}
    </template>
  </Multiselect>
</template>
