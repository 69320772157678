const dayjs = require('dayjs')
var customParseFormat = require('dayjs/plugin/customParseFormat')
var minMax = require('dayjs/plugin/minMax')
var weekYear = require('dayjs/plugin/weekYear') // dependent on weekOfYear plugin
var weekOfYear = require('dayjs/plugin/weekOfYear')
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)
dayjs.extend(minMax)
dayjs.extend(customParseFormat)

const updateLocale = require('dayjs/plugin/updateLocale')
dayjs.extend(updateLocale)

require(`dayjs/locale/en`);
require(`dayjs/locale/ru`);
require(`dayjs/locale/tr`);
require(`dayjs/locale/it`);
require(`dayjs/locale/es`);
require(`dayjs/locale/de`);
require(`dayjs/locale/fr`);



dayjs.updateLocale('en', {weekStart: 1})
dayjs.updateLocale('tr', {weekStart: 1})
dayjs.updateLocale('ru', {weekStart: 1})
dayjs.updateLocale('it', {weekStart: 1})
dayjs.updateLocale('es', {weekStart: 1})
dayjs.updateLocale('de', {weekStart: 1})
dayjs.updateLocale('fr', {weekStart: 1})

export default{

    methods: {
        dayjs,
        StringToDate(date){
            return dayjs(date, this.format)
        },

        localDate(date, dateType) {
            if (date === 'today') {
                return dayjs().format('MMM DD, YY');
            } 
            if (dateType){ {
                if (dateType==="Week") {
                    const weekYearMatch = date.match(/^week(\d{1,2})Year(\d{4})$/);
        
                  if (weekYearMatch && weekYearMatch.length === 3) {
                    const weekNumber = parseInt(weekYearMatch[1], 10);
                    const year = parseInt(weekYearMatch[2], 10);
        
                    return `Week ${weekNumber}, ${year}`
                     } else {
                    // console.log("error",date);
                    return '';
                }
                }
                else if(dateType==="Month"){
                    const monthYearMatch = date.match(/^month(\d{1,2})Year(\d{4})$/);
        
                    if (monthYearMatch && monthYearMatch.length === 3) {
                        const monthNumber = parseInt(monthYearMatch[1], 10);
                        const year = parseInt(monthYearMatch[2], 10);
            
                        return `${dayjs().month(monthNumber-1).format("MMMM")}, ${year}`
                    } else {
                        // console.log("error",date);
                        return '';
                    }}
                }
            }  
            return dayjs(date, "YYYY-MM-DD").format("MMM DD, YYYY");
        },                     

        getWeek(date){
            return dayjs(date,"YYYY-MM-DD").week()

        },

        getMonth(date){
            return dayjs(date,"YYYY-MM-DD").month()
        },

        getYear(date){
            return dayjs(date,"YYYY-MM-DD").year()
        },

        localDateTime(date){
            return dayjs(date,'YYYY-MM-DD HH:mm:ss').format("MMM DD, YYYY HH:mm")
        },

        toDate(date, format){
            return dayjs(date, format)
        },

        minDate(dates = []){
            return dayjs.min(...dates)
        },

        diffDate(date){
            return dayjs().diff(date, 'day')
        },

        diffTwoDate(date1, date2){
            return dayjs(date1, 'YYYY-MM-DD').diff(dayjs(date2, 'YYYY-MM-DD'),'day')
        },

        subtrack(date, day){
            return dayjs(date, 'YYYY-MM-DD').subtract(day,'day').format('YYYY-MM-DD')
        },

        add(date, day){
            return dayjs(date, 'YYYY-MM-DD').add(day, 'day').format('YYYY-MM-DD');
        },

        getWeeksBetween(startDate, endDate) {
            const weeks = [];
            let currentDate = dayjs(startDate);
        
            while (currentDate.isBefore(endDate)) {
                weeks.push(`week${currentDate.week()}Year${currentDate.year()}`);
                currentDate = currentDate.add(1, 'week');
            }
        
            return weeks;

        },

        getMonthsBetween(startDate,endDate){
            const months = [];
            
            let startMonth=dayjs(startDate).month();
            const endMonth=dayjs(endDate).month();

            while(!(startMonth>endMonth))
            {
                months.push(`month${startMonth}Year${dayjs(startDate).year()}`)
                startMonth++;
            }
            return months;
        },

        getDaysBetween(startDate,endDate){
            const days = [];
            let currentDate = dayjs(startDate);
          
            while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, 'day')) {
              days.push(currentDate.format('YYYY-MM-DD'));
              currentDate = currentDate.add(1, 'day');
            }
          
            return days;
        },

        getDaysOfWeek(weekYearKey){
            const weekNumber = parseInt(weekYearKey.match(/\d+/)[0]);
            const year = parseInt(weekYearKey.match(/\d+/g)[1]);
        
            const startDate = dayjs().year(year).startOf('year').add(weekNumber - 1, 'weeks').startOf('week').format('YYYY-MM-DD');
            const endDate = dayjs(startDate).add(1, 'week').subtract(1, 'day').format('YYYY-MM-DD');
        
            return {
                startDate,
                endDate
            };

        },

        getDaysOfMonth(monthYearKey) {
            const monthNumber = parseInt(monthYearKey.match(/\d+/)[0]);
            const year = parseInt(monthYearKey.match(/\d+/g)[1]);
        
            const startDate = dayjs().year(year).month(monthNumber - 1).startOf('month').format('YYYY-MM-DD');
            const endDate = dayjs(startDate).endOf('month').format('YYYY-MM-DD');
        
            return {
                startDate,
                endDate
            };
        },

        generateDatePeriods(startDate, endDate) {
            const ranges = [];
            let currentDate = dayjs(startDate);
          
            while (currentDate.isBefore(dayjs(endDate))) {
              const nextDate = currentDate.add(90, 'day');
              const range = {
                startDate: currentDate.format('YYYY-MM-DD'),
                endDate: nextDate.isBefore(dayjs(endDate)) ? nextDate.format('YYYY-MM-DD') : endDate,
              };
              ranges.push(range);
              currentDate = nextDate.add(1,'day');
            }          
            return ranges;

        },
        today(){
            return dayjs().format('YYYY-MM-DD')
        }
    },

    data(){
        return{
            format:'YYYY-MM-DD',
            months :  [
                "January", "February", "March", "April", "May", "June", "July",
                "August", "September", "October", "November", "December"
              ]

        }
    }


}