<script>
export default {
    props: {
        set: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        saveChip() {
            const { chips, currentInput, set } = this;
            if (currentInput && this.isValidEmail(currentInput)) {
                ((set && chips.indexOf(currentInput) === -1) || !set) && chips.push(currentInput);
                this.$emit('chipsChanged', chips)
                this.currentInput = '';
                this.isValid = true;
            } else if (currentInput) {
                // Show a warning message about invalid email
                this.isValid = false;
            } else {
                // Clear the error message if input is empty
                this.isValid = true;
            }
        },
        // Email validation function
        isValidEmail(email) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        },
        deleteChip(index) {
            this.chips.splice(index, 1);
            // Clear the error message when a chip is deleted
            this.isValid = true;
        },
        backspaceDelete({ which }) {
            which == 8 && this.currentInput === '' && this.chips.splice(this.chips.length - 1);
            // Clear the error message when the backspace is pressed
            this.isValid = true;
        },
        getChips() {
            return this.chips;
        },

    },
    data() {
        return {
            chips: [],
            currentInput: '',
            isValid: true
        };
    },

    
};
</script>


<template>
    <div class="chip-container form-control">
        <div class="chip" v-for="(chip, i) of chips" :key="chip.label">
            {{ chip }}
            <i class="ri-close-line" @click="deleteChip(i)"></i>
        </div>
        <input :placeholder="chips.length == 0 ? 'Enter email' : null" class="" v-model="currentInput"
            @keypress.enter="saveChip" @keydown.delete="backspaceDelete" />
        <!-- Move the warning message into the chip-container and position it absolutely -->
        <div v-if="!isValid" class="warning-message">
            <b-alert show variant="danger">Please enter a valid email</b-alert>
        </div>
    </div>
</template>



<style scoped>
.chip-container {
    width: 100%;
    border: 1px solid #ccc;
    min-height: 45px;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    padding: 2px;
}

.chip {
    margin: 0px 4px;
    padding: 0px 8px;
    /* Increase padding for a smoother look */
    border: 1px solid #5cad8f;
    border-radius: 20px;
    /* Increase border-radius for a more rounded appearance */
    display: flex;
    align-items: center;
    background-color: #C3DCC1;
    /* Add a background color for contrast */
}

.warning-message {
    position: absolute;
    top: 100%;
    width: 100%;
    /* Set the width to 100% */
    max-width: 93%;
    /* Ensure the error message doesn't stretch beyond container */
    background-color: white;
    z-index: 90;
    padding: 0px 0;
    /* Adjust the padding as needed */
}

.chip-container i {
    cursor: pointer;
    opacity: 0.56;
    margin-left: 8px;
    color: #25724b;
}

.chip-container input {
    flex: 1 1 auto;
    width: 30px;
    border: none;
    outline: none;
    padding: 4px;
}
</style>