import axios from 'axios';
import {BASE_URL, FRONTEND_URL} from '../config.js'

const TOKEN = 'token';

const getToken = () => localStorage.getItem(TOKEN) ? localStorage.getItem(TOKEN) : null

const getAuthorizationHeader = () => `Bearer ${getToken()}`;

const getHeader = () => {
  return {
  Authorization: getAuthorizationHeader(),
  'Content-Type': 'application/json',
  accept:'*/*'  
}};


const getFormDataHeader = () => {
  return {
  Authorization: getAuthorizationHeader(),
  'Content-Type': 'multipart/form-data',
  accept:'*/*'  
}};


const isTokenError = (status) => status === 401;

const authRequest = axios.create({
  baseURL: BASE_URL,
  timeout: 159990,
});

const logoutUser = () => {
  window.localStorage.removeItem(TOKEN);
  window.localStorage.removeItem("user")
  try{window.localStorage.removeItem("selected_account")}
  catch(e){console.log(e)}
  authRequest.defaults.headers.Authorization = '';
};

const errorInterceptor = (error) => {
  const { status } = error.response;
  if (isTokenError(status)) {
      logoutUser();
      window.location.href = FRONTEND_URL + "login/"
  }
  return Promise.reject(error);
};

authRequest.interceptors.response.use(
  (response) => response, // this is for all successful requests.
  (error) => errorInterceptor(error), // handle the request
);

export { TOKEN,  logoutUser, authRequest, errorInterceptor,  getHeader, getAuthorizationHeader, getFormDataHeader };