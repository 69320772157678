
export const state = {
    selected_shipments: null
}

export const mutations = {
    SET_SHIPMENTS(state, selected_shipments) {
        state.selected_shipments = selected_shipments;
      },

}

export const actions = {
    setShipments({ commit },  selected_shipments ) {
       commit("SET_SHIPMENTS", selected_shipments);
      },
}