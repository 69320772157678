<script>
export default {
  inheritAttrs: false,

  props: {
    header: { type: String, default: null },
    description: { type: String, default: null },
    color: { type: String, default: "soft-primary-subtle" },
    className: { type: String, default: null },
    version: { type: Number, default: 1 },
    padding: { type: String, default: ".5rem" },
    collapsable: { type: Boolean, default: false },
    defaultVisibility: { type: Boolean, default: true }
  },

  mounted() {
    this.visible = this.defaultVisibility
  },

  data() {
    return {
      visible: true
    }
  }
};
</script>

<template>
  <div v-if="version == 0" :class="className">
    <div class="d-flex d-flex-inline p-0">
      <h3 class="fs-14 text-capitalize title">
        <span class="fw-bolder"> {{ header }} </span>
      </h3>
      <slot class="text-end" name="end" />
    </div>
    <div class="rounded border border-1 p-2">
      <slot />
    </div>
  </div>

  <div v-if="version == 2" :class="className">
    <div class="d-flex d-flex-inline p-0 mt-3 mb-3">
      <h3 class="fs-14 text-capitalize title">
        <span class="fw-bolder"> {{ header }} </span>
      </h3>
      <slot class="text-end" name="end" />
    </div>
    <div class="rounded card p-0">
      <slot />
    </div>
  </div>

  <div v-if="version == 1" :class="className">
    <div bo-body class="h-100 m-0 p-0 card">
      <b-card-header @click="visible = !visible" class="align-items-center d-flex m-0 bg-primary-subtle"
        :class="{ 'cursor-pointer': collapsable }" :style="{ padding }">
        <span class="mb-0 p-0 flex-grow-1 d-flex gap-1">
          <span v-if="header != null" class="fw-bold fs-16 ms-3">{{ header }}</span>
          <span v-if="description != null" class="text-muted fw-bold" style="margin-top: .4rem;">( {{ description }}
            )</span>
          <span v-else>
            <slot name="start" />
          </span>
        </span>

        <span class="float-end me-3">
          <slot name="end" />
        </span>
      </b-card-header>
      <b-collapse v-if="collapsable" v-model="visible">
        <b-card-body class="m-0 p-0">
          <slot />
        </b-card-body>
      </b-collapse>
      <b-card-body v-else class="m-0 p-0">
        <slot />
      </b-card-body>

    </div>
  </div>
</template>
