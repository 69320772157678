/* eslint-disable no-unused-vars */
import { createApp } from "vue";
import App from "./App.vue";
import router from "@/router";
import AOS from "aos";
import "aos/dist/aos.css";
import store from "@/state/store";
import chip from "@/components/chip.vue";
import BootstrapVue3 from "bootstrap-vue-3";
import vClickOutside from "click-outside-vue3";
import Maska from "maska";
import VueFeather from "vue-feather";
import "@/assets/scss/config/material/app.scss";
import "@vueform/slider/themes/default.css";
import "@/assets/scss/mermaid.min.css";
import "@/assets/scss/recaptcha.css";
//import '@/assets/scss/vue-multiselect-bootstrap5.scss';
import Layout from "@/layouts/main";
import Notifications from "@kyvg/vue3-notification";
//import '@/assets/scss/notification.scss'
import loader from "vue-ui-preloader";
import preLoader from "@/components/preLoader.vue";
import componentLoader from "@/components/component-loader.vue";
import componentHeader from "@/components/component-header.vue";
import product from "@/components/product.vue";
//import loader from "@/components/loader.vue"
import "floating-vue/dist/style.css";
import FloatingVue from "floating-vue";
import mitt from "mitt";
import globalmixin from "@/mixins/global";
export const emitter = mitt();
import MarketplaceFilter from "@/components/filters/marketplace-filter";
import ProductFilter from "@/components/filters/product-filter";
import DateFilter from "@/components/filters/date-filter"
import OrderBy from "@/components/filters/order-by";
import { SimpleBar } from 'simplebar-vue3'
import 'simplebar/dist/simplebar.min.css';
import GenericTable from "@/components/GenericTable"
import NoResult from "@/components/NoResult"
import "flatpickr/dist/flatpickr.css";
import "@/assets/scss/default.css";


AOS.init({
  easing: "ease-out-back",
  duration: 1000,
});

const app = createApp(App);
app
  .component("layout", Layout)
  .component("pre-loader", preLoader)
  .component("component-loader", componentLoader)
  .component("component-header", componentHeader)
  .component("generic-table",GenericTable)
  .component("no-result", NoResult)
  .component("chip", chip)
  .component("product", product)
  .component("marketplace-filter", MarketplaceFilter)
  .component("date-filter", DateFilter)
  .component("product-filter", ProductFilter)
  .component("order-by", OrderBy)
  .component('SimpleBar', SimpleBar)
  .mixin(globalmixin)
  .use(store)
  .use(router)
  .use(BootstrapVue3)
  .component(VueFeather.type, VueFeather)
  .use(Maska)
  .use(Notifications)
  .use(loader)
  .use(FloatingVue)
  .use(vClickOutside)
  .mount("#app");

window.global_store = store;
window.onerror = function (msg, url, line, col, error) {
  //alert(msg, url, line, col, error)
};

window.addEventListener("unhandledrejection", function (event) {
  //handle error here
  //event.promise contains the promise object
  //event.reason contains the reason for the rejection
  //alert(`${event.reason} ----`)
});
