<script>
import { emitter } from "@/main"

export default{
    props: {
        data:{type:Array, default:  null},
    },

    mounted(){
        setTimeout(()=>{
        const simplebarInstance = document.querySelector('.modal-body');
        simplebarInstance.addEventListener('scroll', this.debounce(this.handleScroll, 20));

        },1000)
        
    },

    unmounted(){

    },

    methods:{
        handleScroll() {
            const container = document.querySelector('.modal-body');
            const nearBottom = Math.abs(
                container.scrollHeight - container.clientHeight - container.scrollTop
            ) <= 1;
            if (nearBottom ) {
                emitter.emit('onScrollEvent');
            }
        },
        debounce(func, wait, immediate) {
            let timeout;
            return function () {
                const context = this, args = arguments;
                const later = function () {
                    timeout = null;
                    if (!immediate) func.apply(context, args);
                };
                const callNow = immediate && !timeout;
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
                if (callNow) func.apply(context, args);
            };
        },
    }
}
</script>

<template>
    <div v-if="data.length>0" id="generic-table-container" >
        <table  class="table table-card  table-responsive align-middle mb-0 m-0 p-0">
            <thead class="table-light">
                <tr class="text-uppercase text-center">
                    <th v-for="col,i in Object.keys(data[0])" :key="i">{{ this.camelCase(col) }}</th>
                    <template>
                        <slot name="end"/>
                    </template>
                </tr>
            </thead>
            <tbody class="text-center">
                <tr v-for="item,i in data" :key="i">
                    <td v-for="val, index in Object.values(item)" :key="index">{{val}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <no-result v-else/>
</template>


