<script>
import Multiselect from "@vueform/multiselect";

export default{
    props: {
        options:{type: Array, default:null},
        sort: {type: Boolean, default:true},
        default_value:{type: String, default:null},
    },

    emits: ['onSelect', 'onSort'],


    mounted(){
        this.value=this.default_value
        this.sort_by=this.default_value
    },

    components: { Multiselect },

    methods:{
        selected(){
            this.$emit('onSelect', this.value)
        },
    },

    data(){
        return{
            value:'',
            sort_by:'',
        }
    },

    watch:{
        sort_by(){
            this.$emit('onSort', this.sort_by)
        }
    }




}

</script>

<template>
        <Multiselect ref="order_by"
  v-model="value"
  class="form-control"
  :close-on-select="true"
  :searchable="true"
  :create-option="true"
  :canClear="false" 
  :canDeselect="false"
  :options=this.options
  placeholder="Order by"
  @select="selected"
/>

<b-form-checkbox v-model="sort_by" switch>
    <b>{{sort_by ? 'asc': 'desc'}}</b>
    </b-form-checkbox>

</template>