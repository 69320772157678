<script>

export default {
    props: {
        item: { type: Object, default: null }
    },

    computed: {
        sellerIds: {
            get() {
                return this.$store.state.account.account.sp_api_tokens.map((vals) => { return vals.sellerId })
            }
        },
    },

    methods: {
        details() {
            let result = {}
            try{
            this.item.offers.forEach(el => {

                try{
                if (el.IsBuyBoxWinner && this.sellerIds.includes(el.SellerId)) {
                    result['el'] = el
                    result['isWinner'] = true
                }
                else if (el.IsBuyBoxWinner) {
                    result['el'] = el
                    result['isWinner'] = false
                }}
                catch{
                    return false
                }

            }

            );
        }
        catch{
            return false
        }


            return result
        },

    }

}
</script>

<template>

    <div class="vstack" style="max-width: 100px;" v-if="details()">
        <span class="badge" :class="{
            'badge-soft-success': details().isWinner,
            'badge-soft-danger': !details().isWinner}">{{ `${details().isWinner ? 'BuyBoxWon' : 'BuyBox Lost'}`}}
        </span>
        <span class="text-muted fw-bold fs-10">{{iso_to_local(item.lost_date_time)}}</span>
    </div>
    <div v-else>
    </div>

</template>