<!-- eslint-disable no-unused-vars -->
<script>
import { period1 } from "@/utils/date-range"; //eslint-disable-line
import Multiselect from "@vueform/multiselect";
import { dateMethods } from "@/state/helpers"
import flatPickr from "vue-flatpickr-component";
import DateCalculations from "@/mixins/DateCalculations";

export default {
  props: {
    dateRange: { type: Array, default: period1 },
    canDeselect: { type: Boolean, default: false },
    canClear: { type: Boolean, default: false },
    placeholder: { type: String, default: 'Date Range' },
    defaultSelected:{type:Number,default:0}
  },

  computed: {
    value: {
      get() {
        return window.global_store.state.date.date
      },
      set(val) {
        this.setDate(val)
      }
    },
  },


  mixins: [DateCalculations],

  components: { Multiselect, flatPickr },

  mounted() {
    let date = window.global_store.state.date.date
        if (date)
        this.$refs.multiselect.select(date)

    else  {
      this.$refs.multiselect.select(this.dateRange[this.defaultSelected])
    }
    //this.value == [] || this.value == null ? this.$refs.multiselect.select(this.dateRange[0]) : [] 
  },


  methods: {
    ...dateMethods,
    getCustomDate(value) {

      try {
        return `${this.localDate(this.startDate)} - ${this.localDate(this.endDate)}`
      } catch {
        return "Custom"
      }

    },

    flatPickerClosed() { },


  },
  data() {
    return {
      startDate: '',
      endDate: '',
      preStartDate: '',
      preEndDate: '',
      flatPickerDate: null,
      flatPickerConfig: {
        dateFormat: "Y-m-d",
        altFormat: "Y-m-d",
        onClose: (selectedDates, dateStr, instance) => {

          var selectedPeriodStartDate = this.toDate(instance.formatDate(selectedDates[0], 'Y-m-d'), 'YYYY-MM-DD').format('YYYY-MM-DD');
          var selectedPeriodEndDate = this.toDate(instance.formatDate(selectedDates[1], 'Y-m-d'), 'YYYY-MM-DD').format('YYYY-MM-DD')
          var previousPeriodEndDate = this.subtrack(selectedPeriodStartDate, 1)
          var previousPeriodStartDate = this.subtrack(previousPeriodEndDate, this.diffTwoDate(selectedPeriodEndDate, selectedPeriodStartDate))

          this.startDate = selectedPeriodStartDate
          this.endDate = selectedPeriodEndDate

          var displayDate = `${this.localDate(this.startDate)} - ${this.localDate(this.endDate)}`

          this.preStartDate = previousPeriodStartDate
          this.preEndDate = previousPeriodEndDate

          this.$refs.multiselect.select(
            {
              isCustom: true,
              value: dateStr,
              label: dateStr,
              displayDate: displayDate,

              partition: ['selectedPeriod', 'previousPeriod'],
              date:()=>{
                return {startDate:selectedPeriodStartDate, endDate:selectedPeriodEndDate}
              },
              dateStr: () => {
                return [
                  { selectedPeriod: { startDate: selectedPeriodStartDate, endDate: selectedPeriodEndDate } },
                  { previousPeriod: { startDate: previousPeriodStartDate, endDate: previousPeriodEndDate } }, 
                ]
              }
            }
          )
          this.$refs.multiselect.close()
        },
        mode: "range",
        clickOpens: true,
        inline: false,
        //positionElement : document.getElementById("multiselect"),
        static: true,
        showMonths: 1,
        locale: null,
        wrap: false,
        noCalendar: false,
      },
      optionsMultiSelect: this.dateRange,
    };
  },

  watch: {
    lang(newVal, oldVal) {
      this.getLocale()
    },

  }

};
</script>

<template>
  <Multiselect ref="multiselect" data-dropdown-mode="filter" class="form-control"
    :classes="{
      optionSelected: ' bg-soft-primary',
      option: `form-control border-0 flex items-center justify-start box-border text-left cursor-pointer text-base leading-snug py-2 px-3 ${dateRange.length >7 && 'w-50'}`,
      options:`multiselect-options ${dateRange.length>7 && 'flex-row flex-wrap'}`
    }"
    v-model="value" :allow-absent="true"
    :canDeselect="canDeselect" :object="true" :placeholder="placeholder" :closeOnSelect="true" :clearOnBlur="false"
    :canClear="canClear" :searchable="false" :createOption="true" :appendNewOption="true" :options="this.dateRange">

    <!--eslint-disable-next-line vue/no-unused-vars-->
    <template v-slot:singlelabel="{ value }">
      <div class="multiselect-single-label ">
        <span v-if="!value.isCustom">{{ value.label }}</span>
        <span v-else>{{ value.displayDate }}</span>
      </div>
    </template>

    <template v-slot:option="{ option }">
      <span :v-if="option.option != 'custom'">{{ option.label }}</span>
    </template>

    <template v-slot:afterlist>
      <flat-pickr id="datePicker" @on-close="flatPickerClosed" v-model="flatPickerDate" :config="flatPickerConfig"
        class="form-control" placeholder="Custom daterange" />
    </template>

  </Multiselect>
</template>

<style scoped>
.multiselect-optionsum{
  display: flex;
  flex-direction: row!important;;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}
</style>